body {
  padding: 0;
  margin: 0;
}
.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  height: 100vh;
  padding: 10px;
}

.game {
  width: 560px;
  height: 560px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}

.game img {
  width: 70px;
  height: 70px;
}

.score-board {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.darkModeBtn {
  width: 80px;
  height: 30px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.items {
  margin: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.game-title {
  margin-right: auto;
  margin-left: 40px;
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
